<template>
  <div :style="cssStyle" ref="node">
    <slot v-if="showPending" />
  </div>
</template>

<script>
import throttle from "utils/throttle";

function isScrollable(overflow) {
  return overflow !== "hidden" && overflow !== "visible";
}

function getClosestScrollParent(self) {
  let scrollY = false;
  let node = self;

  while (!scrollY && node.parentNode) {
    node = node.parentNode;
    const style = window.getComputedStyle(node);
    scrollY = isScrollable(style["overflow-y"]) || isScrollable(style.overflow);
  }

  return node;
}

export default {
  name: "InfiniteLoader",
  props: {
    cssStyle: Object,
    useDocument: Boolean, // 使用 window 作为 scroll 容器
    onTrigger: Function,
    offset: {
      type: Number,
      default: 50,
    },
    direction: {
      type: String,
      default: "bottom",
    },
    delay: {
      type: Number,
      default: 300,
    },
    triggerOnCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPending: false,
    };
  },
  created() {
    this.lastScrollTop = 0;
    if (this.triggerOnCreate) this.doTrigger();
  },
  mounted() {
    this.scrollDom = this.useDocument
      ? window
      : getClosestScrollParent(this.$refs.node);
    this.getScrollHeight = () => this.scrollDom.scrollHeight;
    this.getClientHeight = () => this.scrollDom.clientHeight;
    this.getScrollTop = () => this.scrollDom.scrollTop;

    if (this.scrollDom === window) {
      this.getScrollHeight = this.getWindowHeight;
      this.getClientHeight = this.getWindowClientHeight;
      this.getScrollTop = this.getWindowScrollTop;
    }
    this.onScroll = throttle(this.scrollHandle, 50);
    this.scrollDom.addEventListener("scroll", this.onScroll, false);
  },
  beforeDestroy() {
    this.scrollDom.removeEventListener("scroll", this.onScroll, false);
  },
  methods: {
    _scrollTo(offset) {
      this.scrollDom.scrollTop = offset;
    },
    scrollToTop() {
      this._scrollTo(0);
    },
    scrollToBottom() {
      this._scrollTo(this.scrollDom.scrollHeight);
    },
    isPending() {
      return this._pending === true;
    },
    actionPending() {
      this._pending = true;
      if (this.delay > 0) {
        this._showPendingTimer = setTimeout(() => {
          this._showPendingTimer = null;
          if (this._pending) {
            this.showPending = true;
          }
        }, this.delay);
      } else {
        this.showPending = true;
      }
    },
    finishPending() {
      if (this._showPendingTimer) {
        clearTimeout(this._showPendingTimer);
      }
      this._pending = false;
      this.showPending = false;
    },
    getWindowScrollTop() {
      return window.scrollY || document.documentElement.scrollTop;
    },
    getWindowClientHeight() {
      return window.innerHeight;
    },
    getWindowHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
    },
    scrollHandle(e) {
      if (this.isPending()) {
        return;
      }

      const curScrollTop = this.getScrollTop();
      const { direction, offset } = this;
      let touchBoundary;
      if (direction === "top") {
        const isAlreadyInTrigger = this.lastScrollTop - offset <= 0;
        if (!isAlreadyInTrigger) {
          touchBoundary = curScrollTop - offset <= 0;
        }
      } else {
        const isAlreadyInTrigger =
          this.lastScrollTop > 0 && // 不是初始位置
          this.lastScrollTop + this.getClientHeight() >=
            this.getScrollHeight() - offset;
        if (!isAlreadyInTrigger) {
          touchBoundary =
            curScrollTop + this.getClientHeight() >=
            this.getScrollHeight() - offset;
        }
      }

      if (touchBoundary) {
        this.doTrigger();
      }

      this.lastScrollTop = curScrollTop;
    },
    doTrigger() {
      this.actionPending();
      this.onTrigger(this.finishPending);
    },
  },
};
</script>
