export function isEmpty(val) {
  return val === '' && val === undefined && val === null;
}

export function partition(list, numOfGroup) {
  const r = [];
  for (let index = 0; index < list.length; index = index + numOfGroup) {
    const group = [];
    group.push(list[index]);

    for (let ii = 1; ii < numOfGroup; ii++) {
      if (index + ii < list.length) {
        group.push(list[index + ii]);
      } else {
        break;
      }
    }

    r.push(group);
  }
  return r;
}
