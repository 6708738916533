<template>
  <transition name="fade">
    <div v-if="show" :class="cssClass">
      <a v-if="showClose" class="Notify__close" title="关闭" @click="handleClose">×</a>
      <div class="Notify__main">
        <div class="Notify__text">{{text}}</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Pop',
  props: {
    showClose: {
      default: false,
    },
    timeout: {
      default: 1500,
    },
    text: {},
    type: {},
    show: {},
  },
  mounted() {
    this.autoClose();
  },
  updated() {
    if (this._timer) {
      clearTimeout(this._timer);
    }

    this.autoClose();
  },
  computed: {
    cssClass() {
      if (this.type) {
        return `Notify Notify--${this.type}`;
      }

      return 'Notify';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    autoClose() {
      const { timeout } = this;
      if (timeout) {
        this._timer = setTimeout(this.close, timeout);
      }
    },
  },
};
</script>

<style>
.fade-enter-active {
  transition: opacity 225ms ease-out;
}

.fade-leave-active {
  transition: opacity 195ms ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
