import Vue from 'deps/vue';
import VueLazyload from 'deps/vueLazyload'; //懒加载
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import ImgLoading from './assets/loading.jpeg';

Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
Vue.use(VueLazyload, {
  loading: ImgLoading,
});

export default Vue;
