import { setConfig } from 'helper/configManager';

const config = {
  apiVersion: '1.0'
};
let envConfig;

let env = process.env.FEA_APP_CONFIG;
if (process.env.NODE_ENV === 'development') {
  env = 'pre';
}

if (env === 'test') {
  envConfig = {
    apiW2Endpoint: '//w2api-test.lifevc.com/',
    apiRestEndpoint: '//rest.lifetest.com/',
    apiToken: 'QWN0QXBpfDEyNHM0ZGNib2luYjg0NTg0NzEyYm9pZWdrZmpr',
  };
} else if (env === 'pre') {
  envConfig = {
    apiW2Endpoint: '//w2api-pre.lifevc.com/',
    apiRestEndpoint: '//rest-pre.lifevc.com/',
    apiToken: 'QWN0QXBpfDZjYXRyZW9mMGpwbzM4ZWI0MElINDY1ZGtsZnVv',
  };
} else {
  envConfig = {
    apiW2Endpoint: '//w2api.lifevc.com/',
    apiRestEndpoint: '//rest.lifevc.com/',
    apiToken: 'QWN0QXBpfDZjYXRyZW9mMGpwbzM4ZWI0MElINDY1ZGtsZnVv',
  };
}

setConfig({ ...config, ...envConfig });
