function createParams(key, value) {
  return `${key}=${encodeURIComponent(value)}`;
}

function defaultquerySerializer(obj) {
  if (obj === null || obj === undefined) {
    return '';
  }

  return (
    Object.keys(obj)
      // eslint-disable-next-line eqeqeq
      .filter(key => obj[key] != undefined)
      .map(key => {
        const value = obj[key];
        if (value instanceof Array) {
          return value.map(val => createParams(key, val)).join('&');
        }

        return createParams(key, value);
      })
      .join('&')
  );
}

export function makeUrl(url, query, querySerializer = defaultquerySerializer) {
  if (!query) {
    return url;
  }

  let mergedQuery = {};
  let originPath = url;
  let originSearch = '';
  const searchIndex = url.indexOf('?');
  if (searchIndex !== -1) {
    originPath = url.slice(0, searchIndex);
    originSearch = url.slice(searchIndex + 1);
    mergedQuery = parseQueryString(originSearch);
  }

  Object.keys(query).forEach(key => {
    const src = mergedQuery[key];
    const target = query[key];
    if (src) {
      if (target) {
        if (Array.isArray(src)) {
          mergedQuery[key] = src.concat(target);
        } else if (Array.isArray(target)) {
          mergedQuery[key] = [src].concat(target);
        } else {
          mergedQuery[key] = target;
        }
      } else {
        mergedQuery[key] = src;
      }
    } else {
      mergedQuery[key] = target;
    }
  });

  return `${originPath}?${querySerializer(mergedQuery)}`;
}

export function parseQueryString(str, { array = false } = {}) {
  let queryString = str;
  if (queryString.indexOf('?') === 0) {
    queryString = queryString.slice(1);
  }

  const vars = queryString.split('&');
  const query = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    const value = pair[1] === undefined ? '' : decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query[pair[0]] === 'undefined') {
      query[pair[0]] = value;
      // If second entry with this name
    } else if (array) {
      if (typeof query[pair[0]] === 'string') {
        const arr = [query[pair[0]], value];
        query[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        query[pair[0]].push(value);
      }
    }
  }
  return query;
}

export const urlQuery = parseQueryString(window.location.search);
