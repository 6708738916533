<template>
  <div :class="$style.item" @click="click">
    <AspectRatioPlaceholder :width="1" :height="1">
      <img :class="$style.itemImg" v-lazy="{ src: data.Image, loading: ImgLoadingGood }" />
    </AspectRatioPlaceholder>

    <div :class="$style.itemDetail">
      <div :class="$style.itemName" class="u-noWrapWithEllipsis" :title="data.Name"
        ><span>{{ data.Name }}</span>
      </div>
      <div :class="$style.itemText1" class="u-noWrapWithEllipsis"
        ><span>{{ data.Appeal }}</span>
      </div>
      <div :class="$style.price">
        <span :class="$style.promptPrice"
          ><span :class="$style.priceSymbol">￥</span
          >{{ data.ActivityPrice > 0 ? data.ActivityPrice : data.SalePrice }}</span
        ><span v-if="data.ActivityPrice > 0" :class="$style.salePrice"
          ><span :class="$style.priceSymbol">￥</span>{{ data.SalePrice }}</span
        >
      </div>
      <div v-if="!hideTags" :class="$style.tags">
        <div
          v-if="data.ActivityTag"
          :class="$style.tag"
          :style="{ color: '#000', backgroundColor: '#ffcb0b' }"
          ><div :class="$style.tagText">{{ data.ActivityTag }}</div>
        </div>
        <template v-for="(tag, index) in data.SaleTags">
          <div
            :class="$style.tag"
            :style="{ color: `#${tag.Color}`, backgroundColor: `#${tag.BGColor}` }"
            :key="index"
          >
            <div :class="$style.tagText">{{ tag.Text }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ImgLoadingGood from "../../assets/loading-good.png";
import AspectRatioPlaceholder from "base-components/AspectRatioPlaceholder";

// Image: "https://i1.lifevccdn.com//upload/goods//upload/goods/D1704201923144854.jpg"
// ItemInfoId: 21599
// Name: "酵素洗衣液(超强洁净)"
// SalePrice: 49
export default {
  name: "GoodItem",
  props: {
    data: {},
    hideTags: Boolean,
  },
  components: {
    AspectRatioPlaceholder,
  },
  data() {
    return {
      ImgLoadingGood,
    };
  },
  methods: {
    click() {
      this.$emit("click", this.data);
    },
    action() {
      this.$emit("action", this.data);
    },
  },
};
</script>

<style lang="scss" module>
$page-width: 375;
@import "~style/tool";

.item {
  line-height: 1;
  background: #fff;
  border-radius: 2px;
  overflow: hidden;
  padding-bottom: 8px;

  :global {
    .AspectRatioPlaceholder {
      background: transparent;
    }
  }
}

.itemImg {
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 100%;
}
.itemDetail {
  line-height: 1;
}
.itemName {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.itemText1 {
  height: 17px;
  margin-top: 6px;
  font-size: 12px;
  color: #999999;
}

.price {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-left: -2px;
}
.promptPrice {
  font-size: 16px;
  font-weight: 500;
  color: #ab2b2c;
}
.salePrice {
  // margin-top: rem(4);
  margin-left: 3px;
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
}

.tags {
  margin-top: 4px;
  height: 18px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.tag {
  flex: 0 0 auto;
  color: #ab2b2c;
  background-color: rgba(255, 255, 255, 1);
}

.tagText {
  font-size: 12px;
  transform-origin: center;
  transform: scale(0.91);
  padding: 3px 1px;
}

.tag + .tag {
  margin-left: 4px;
}
</style>
