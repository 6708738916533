const navigator = window.navigator;
const ua = navigator.userAgent;

export const isAndroid =
  window.OCmodel !== undefined &&
  (ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1); //android终端
export const isiOS =
  window.OCmodel !== undefined && !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
export const isWebEmbed = window.parent !== window;
// /^https?:\/\/m\.lifevc\.com/.test(window.parent.location.href);
export const isWeChat = !isWebEmbed && /MicroMessenger/i.test(ua);
export const isApp = isAndroid || isiOS;
