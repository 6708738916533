import { isAndroid, isiOS } from 'helper/env';

function getParam(url, name) {
  try {
    const pattern = new RegExp('[?&]' + name + '=([^&]+)', 'g');
    const matcher = pattern.exec(url);
    let items = null;
    if (null != matcher) {
      try {
        items = decodeURIComponent(decodeURIComponent(matcher[1]));
      } catch (e) {
        try {
          items = decodeURIComponent(matcher[1]);
        } catch (e) {
          items = matcher[1];
        }
      }
    }
    return items;
  } catch (err) {
    //在这里处理错误
  }
  return '';
}

export default function createUniversalUrl(url) {
  if (!isAndroid && !isiOS) {
    return url;
  }

  const appOpen = getParam(url, 'appopen') || ''; //参数格式为：打开原生native:{nativepagename}:{param1}:{param2},例如打开详情页：native:iteminfo:123 意思为打开商品详情页，参数为123
  const appOpenTitle = getParam(url, 'appopentitle') || '';
  let openPage = url;
  let openType = 'web';
  let openParam = '';
  const arr = appOpen.split(':');
  if (arr.length > 0) {
    openType = arr[0].indexOf('native') === 0 ? 'native' : 'web';
    if (openType === 'native') {
      if (arr.length > 1) {
        openPage = arr[1];
        if (arr.length > 2) {
          openParam = arr[2];
        }
      }
    }
  }
  switch (openType) {
    case 'native': //原生打开
      const openData = {
        type: openType,
        page: openPage,
        param: openParam,
        title: appOpenTitle,
      };
      const openDataStr = JSON.stringify(openData);
      return 'opennative' + openDataStr;
    default:
      return url;
  }
}
