let config = null;

export function setConfig(cfg) {
  if (config) {
    throw new Error('配置文件只能设置一次');
  }

  config = cfg;
}

export function getConfig() {
  return config || {};
}
