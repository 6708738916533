import Vue from 'vue';
import Pop from './Pop.vue';
import './style.scss';

let pop = document.getElementById('ly-message');
if (!pop) {
  pop = document.createElement('div');
  pop.id = 'ly-message';
}

document.body.appendChild(pop);

const notifyInstance = new Vue({
  render(h) {
    return h(Pop, {
      props: { ...this.config, show: this.isShow },
      on: { close: this._close },
    });
  },
  data() {
    return {
      isShow: false,
      config: {
        showClose: false,
        timeout: 1500,
        type: 'success',
        text: '',
        onClose: null,
      },
    };
  },
  methods: {
    success(text, opt) {
      this._show({
        ...opt,
        text,
        type: 'success',
      });
    },
    info(text, opt) {
      this._show({
        ...opt,
        text,
        type: 'info',
      });
    },
    warn(text, opt) {
      this._show({
        ...opt,
        text,
        type: 'warning',
      });
    },
    error(text, opt) {
      this._show({
        ...opt,
        text,
        type: 'error',
      });
    },
    hide() {
      this._close();
    },
    _show(opt) {
      const showPop = () => {
        this.config = {
          ...opt,
        };
        this.isShow = true;
      };

      if (this.isShow) {
        this._close(showPop);
      } else {
        showPop();
      }
    },
    _close(cb) {
      this.isShow = false;
      this.config = null;
      if (cb) {
        this.$nextTick(cb);
      }
    },
  },
}).$mount(pop);

export default notifyInstance;
