import fectx from 'deps/fectx';
import { parseQueryString } from 'utils/url';
import { getConfig } from 'helper/configManager';
import getToken from './getToken';

const __DEV__ = process.env.NODE_ENV === 'development';
const ENABLE_PROXY = true;

const queryStr = parseQueryString(window.location.search);
const config = getConfig();
const fectxInstance = fectx.create({
  baseURL: __DEV__ && ENABLE_PROXY ? '' : config.apiRestEndpoint,
  timeout: 4 * 1000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'Api-Version': config.apiVersion,
  },
  transformRequest: [data => JSON.stringify(data)],
  validateStatus: _status => _status !== 401 && _status !== 404,
});

fectxInstance.interceptors.request.use(config => {
  if (config.noAuthor !== true) {
    const token = getToken();
    if (token) {
      config.headers['token'] = 'Token ' + token;
      config.headers['Authorization'] = 'Token ' + token;
    }
  }

  let ua;
  if (queryStr.UA) {
    ua = queryStr.UA;
  } else if (queryStr.ua) {
    ua = queryStr.ua;
  }
  if (ua && ua !== '' && ua !== 'null' && ua !== '(null)') {
    config.headers['UA'] = ua;
  }

  if (queryStr.Tracker) {
    config.headers['Tracker'] = queryStr.Tracker;
  }

  return config;
});

if (__DEV__ && ENABLE_PROXY) {
  fectxInstance.interceptors.request.use(fectxConfig => {
    fectxConfig.headers['use-proxy'] = 'true';
    fectxConfig.headers['x-proxy-target'] = 'http:' + config.apiRestEndpoint;
    return fectxConfig;
  });
}
export default fectxInstance;
