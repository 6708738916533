import fectx from 'deps/fectx';
import { getConfig } from 'helper/configManager';

const __DEV__ = process.env.NODE_ENV === 'development';
const ENABLE_PROXY = true;

const config = getConfig();
const fectxInstance = fectx.create({
  baseURL: __DEV__ && ENABLE_PROXY ? '' : config.apiW2Endpoint,
  timeout: 4 * 1000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    ApiToken: config.apiToken,
  },
  transformRequest: [data => JSON.stringify(data)],
  validateStatus: _status => _status !== 401 && _status !== 404,
});

if (__DEV__ && ENABLE_PROXY) {
  fectxInstance.interceptors.request.use(fectxConfig => {
    fectxConfig.headers['use-proxy'] = 'true';
    fectxConfig.headers['x-proxy-target'] = 'http:' + config.apiW2Endpoint;
    return fectxConfig;
  });
}
export default fectxInstance;
