import { urlQuery } from 'utils/url';

const TOKEN_NAME = 'livevc.mobile.home';
let token = urlQuery.Token;
// eslint-disable-next-line eqeqeq
if (token && token != '' && token != 'null' && token != '(null)') {
  localStorage.setItem(TOKEN_NAME, token);
} else {
  token = localStorage.getItem(TOKEN_NAME, token);
}

export default function getToken() {
  return token;
}
