<script>
function cloneVnode(vnode, data, createElement) {
  const cloned = createElement(
    vnode.tag,
    {
      ...vnode.data,
      ...data,
    },
    vnode.children || vnode.text
  );
  cloned.text = vnode.text;
  cloned.isComment = vnode.isComment;
  cloned.componentOptions = vnode.componentOptions;
  cloned.elm = vnode.elm;
  cloned.context = vnode.context;
  cloned.ns = vnode.ns;
  cloned.isStatic = vnode.isStatic;
  cloned.key = vnode.key;
  return cloned;
}

export default {
  name: "AspectRatioPlaceholder",
  computed: {
    ratio() {
      const divide = this.height / this.width;
      return divide * 100;
    },
  },
  props: {
    strict: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    // prefixClz: {
    //   type: String,
    //   default: '',
    // },
  },
  render(h) {
    const fill = h("div", {
      class: {
        AspectRatioPlaceholder__fill: true,
      },
      style: {
        paddingBottom: `${this.ratio}%`,
      },
    });

    const [slotVNode, ...restSlots] = this.$slots.default;
    const clonedSlot = cloneVnode(
      slotVNode,
      {
        class: [
          {
            AspectRatioPlaceholder__embed: "true",
          },
          slotVNode.data && slotVNode.data.class,
        ],
      },
      h
    );
    const media = h(
      "div",
      {
        class: {
          AspectRatioPlaceholder__media: true,
        },
      },
      [clonedSlot, ...restSlots]
    );

    return h(
      "div",
      {
        class: {
          AspectRatioPlaceholder: true,
          // [this.prefixClz]: true,
        },
        style: this.strict
          ? {
              maxWidth: `${this.width}px`,
              maxHeight: `${this.height}px`,
            }
          : {},
      },
      [fill, media]
    );
  },
};
</script>

<style lang="scss">
.AspectRatioPlaceholder {
  position: relative;
  background: rgba(0, 0, 0, 0.05);
}

.AspectRatioPlaceholder__embed {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
}
</style>
